<template>
  <div class="fali">
    <top title="直付失败"></top>
    <div class="pic">
      <img src="@/assets/img/fail.png" alt="" />
    </div>
    <p>直付失败</p>
    <div class="button">
      <van-button color="linear-gradient(to right,rgb(69, 184, 250), #019CF4)" to="qrcode" size="large" round>
        返回
      </van-button>
    </div>
  </div>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>
.fali {
  width: 100%;
  height: 100%;
  .pic {
    width: vw(300);
    height: vw(300);
    margin: vw(20) auto;
    img {
      width: 100%;
    }
  }
  p {
    text-align: center;
    font-size: vw(40);
  }
  .button{
      width:70%;
      font-size: vw(35);
      margin:vw(50) auto;
  }
}
</style>
